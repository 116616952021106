// assets
import { IconDashboard, IconDeviceAnalytics, IconUser, IconBuildingHospital, IconAmbulance, IconMap2, IconMapPin, IconWorld, IconPackages, IconCar, IconList, IconUsers, IconReportMoney, IconExchange, IconBusinessplan  } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconUser: IconUser,
    IconBuildingHospital: IconBuildingHospital,
    IconAmbulance: IconAmbulance,
    IconMap2: IconMap2,
    IconMapPin:IconMapPin,
    IconWorld: IconWorld,
    IconDeviceAnalytics,
    IconPackages:IconPackages,
    IconCar:IconCar,
    IconList: IconList,
    IconUsers: IconUsers,
    IconReportMoney: IconReportMoney,
    IconExchange: IconExchange,
    IconBusinessplan: IconBusinessplan,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons['IconDashboard'],
            breadcrumbs: false,
            roles: ['seller', 'admin'],
        },
        {
            id: 'user-list',
            title: 'Users',
            type: 'item',
            url: '/user-list',
            icon: icons['IconUser'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'trading-house-list',
            title: 'Trading House',
            type: 'item',
            url: '/trading-house-list',
            icon: icons['IconExchange'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'investment-list',
            title: 'Investments',
            type: 'item',
            url: '/investment-list',
            icon: icons['IconBusinessplan'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        
    ]
};
